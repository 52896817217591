import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TablePagination, { TablePaginationProps } from '@material-ui/core/TablePagination'

type Props = TablePaginationProps

const Pagination: React.FunctionComponent<Props> = props => (
  <TableRow>
    <TablePagination
      className=" c=text"
      classes={{ caption: 'inherit-font', spacer: 'hidden' }}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} von ${count}`}
      // rowsPerPageOptions={[]}
      labelRowsPerPage={'Einträge pro Seite'}
      {...props}
    />
  </TableRow>
)

export default Pagination
